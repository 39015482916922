import React, { Component } from 'react';
import { withRouter  } from 'react-router-dom';

import SidebarComponent from '../components/SidebarComponent';
import NavbarComponent from '../components/NavbarComponent';
import HeaderComponent from '../components/HeaderComponent';
import InvoiceProductsComponent from '../components/InvoiceProductsComponent';
import firebase from '@firebase/app';
import '@firebase/storage';
import { validateSession } from  '../utils/functions';

import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

var config = process.env;

class AddInvoicePage extends Component {

	constructor(){
        super();
        this.state = {
            disabled: false,
            checked: false,
            dateSelected: null,
            role: 0,
            products: {},
            rut: 0,
            invoiceNumber: 0,
            purchase: 0,
            isFinalCustomer: true,
            name: "",
            address: ""
        }
	}

	async componentDidMount() {
        let role = await validateSession()
        if (role !== 1)
        {
            window.location = "/purchase"
        }
        else
        {
            const queryParams = new URLSearchParams(this.props.location.search);
            const id = queryParams.get('id');
            if (id !== null && id != undefined && id != "")
            {
                this.setState({purchase:id});
                this.fetchPurchaseData(id)
            }
            this.setState({ checked: true, role:  role});
        }        
    }

    handlerSelectProduct = (value) => {
        this.setState({products: value});
    }

    save = async() => {
        //Convert to dic to list
        var products = [];
        for (const [key, value] of Object.entries(this.state.products)) {
            products.push(value);
        }
        
        this.setState({ disabled: true });
        var token = localStorage.getItem('token');
        var request = {
            method: 'POST',
            headers: { 
                'Content-Type': 'application/json',
                'access-token': token
            },
            body: JSON.stringify({
                rut: parseInt(this.state.rut),
                invoiceNumber: parseInt(this.state.invoiceNumber),
                purchase: parseInt(this.state.purchase),
                isConsFinal: this.state.isFinalCustomer,
                nombre: this.state.name,
                domicilio: this.state.address,
                fecha: new Date(this.state.dateSelected).toLocaleDateString(),
                productos: products,
            })
        };
        let response = await fetch(config.REACT_APP_BASE_URL + config.REACT_APP_VERSION + 'invoice/custom', request)
        const blob = await response.blob();

        if (this.state.purchase !== "" && this.state.purchase != null)
        {
            const storageRef = firebase.storage().ref(`/purchase/${this.state.purchase}/invoice`);
            const task = storageRef.put(blob);
            task.on('state_changed', (snapshot) => {
            }, (error) => {
                this.setState({ enabled: true })
                alert("Se provocó un error")
            }, () => {
                this.setState({ enabled: true })
                window.location = "/addInvoice"
            });
        
        }
        
        const url = window.URL.createObjectURL(blob);
        window.open(url, '_blank');
    }

    onChangeValue = (e) => {
        console.log(e.target)
        const name = e.target.name;
        const value = e.target.value;
        this.setState({ [name]: value.trim()});
    }

    onChangeBooleanValue= (e) => {
        const name = e.target.name;
        const value = e.target.value === "true" || e.target.value === true;
        this.setState({ [name]: value});
    }
    
         // Función de debounce
    debounce = (func, delay) => {
        let timeoutId;
        return function(...args) {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => func.apply(this, args), delay);
        };
    };

    onChangePurchaseValue = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        if (e.target.value !== null && e.target.value !== undefined && e.target.value !== "")
        {
            this.fetchPurchaseData(value)
        }
    }

    fetchPurchaseData = (value) => {        
        var request = {
            method: 'GET',
            headers: { 
                'access-token': localStorage.getItem('token'),
                'Content-Type': 'application/json',
            }
        };
        fetch(`${config.REACT_APP_BASE_URL}${config.REACT_APP_VERSION}purchase/${value}`, request)
        .then(results => { return results.json(); })
        .then(data => {            
            if (data.error > 0) { 
                alert(data.message); 
            } 
            else {

                let productsMap = {}
                data.data.purchase.products.forEach(product => {
                    productsMap[product.prefix  +product.code] = product
                })


                this.setState({
                    purchase: value,
                    name: data.data.purchase.user.firstName,
                    address: data.data.purchase.user.address,
                    products: productsMap,
                    dateSelected: new Date(data.data.purchase.date)
                })
            }
        })
    }



    renderGenericData = () => {
        return(
            <div className="col-lg-12">
                <div className="card card-small mb-4">
                    <div className="card-header border-bottom">
                        <h6 className="m-0">Datos generales</h6>
                    </div>
                <ul className="list-group list-group-flush">
                    <li className="list-group-item p-3">
                    <div className="row">
                        <div className="col">
                            <div className="form-row">                            
                                <div className="form-group col-md-3">
                                    <label>Venta relacionada</label>
                                    <input autoComplete="off" className="form-control" type="text" name="purchase" defaultValue={this.state.purchase} placeholder="Numero de venta" onBlur={(e) => this.onChangePurchaseValue(e)} />
                                </div>                      
                                <div className="form-group col-md-3">
                                    <label>Número de factura</label>
                                    <input autoComplete="off" className="form-control" type="text" name="invoiceNumber" value={this.state.invoiceNumber} placeholder="Numero de factura" onChange={(e) => this.onChangeValue(e)} />
                                </div>
                                <div className="form-group col-md-3">
                                    <label>Consumidor Final</label>
                                    <select id="display" className="custom-select" name="isFinalCustomer" value={this.state.isFinalCustomer} onChange={(e) => this.onChangeBooleanValue(e)}>
                                        <option value="true" >Si</option>
                                        <option value="false" >No</option>
                                    </select>
                                </div>
                                <div className="form-group col-md-3">
                                    <label>RUT</label>
                                    <input autoComplete="off" className="form-control" type="text" name="rut" placeholder="Nombre/Razon social" value={this.state.rut} onChange={(e) => this.onChangeValue(e)} />
                                </div>
                                <div className="form-group col-md-3">
                                    <label>Nombre</label>
                                    <input autoComplete="off" className="form-control" type="text" name="name" placeholder="Nombre/Razon social" value={this.state.name} onChange={(e) => this.onChangeValue(e)} />
                                </div>
                                <div className="form-group col-md-3">
                                    <label>Domicilio Fiscal</label>
                                    <input autoComplete="off" className="form-control" type="text" name="address" placeholder="Domicilio fiscal" value={this.state.address} onChange={(e) => this.onChangeValue(e)} />
                                </div>
                                <div className="form-group col-md-3">
                                    <label for="phone">Fecha</label>
                                    <DatePicker 
                                        className="form-control" 
                                        dateFormat="dd/MM/yyyy"
                                        selected={this.state.dateSelected} 
                                        onChange={(date) => this.setState({ dateSelected: date })}        
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    </li>
                </ul>
                </div>
            </div>
        );
    }

	render() {
        if (!this.state.checked) {
            return (<div></div>);
        } else {
            return (  
                <div className="container-fluid">
                    <div className="row">
                        <SidebarComponent itemSelected={13} role={this.state.role}/>
                        <main className="main-content col-lg-10 col-md-9 col-sm-12 p-0 offset-lg-2 offset-md-3">
                            <NavbarComponent />
                            <div className="main-content-container container-fluid px-4 mb-4" style={{paddingBottom: '80px'}}>
                                <HeaderComponent title="Facturas" subtitle="Generar nueva factura"/>
                                { this.renderGenericData() }
                                <InvoiceProductsComponent products={this.state.products} onChangeData={ this.handlerSelectProduct }/>
                                <button disabled={this.state.disabled} type="button" class="mb-2 btn btn-primary" style={{float: "right", width: "200px", marginRight: "15px"}} onClick={() => this.save()}>Cargar</button>
                            </div>
                        </main>
                    </div>
                </div>
            );
        }
    }
}

export default AddInvoicePage;