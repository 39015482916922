import React, { Component } from 'react';

import SidebarComponent from '../components/SidebarComponent';
import NavbarComponent from '../components/NavbarComponent';
import HeaderComponent from '../components/HeaderComponent';
import TableRestockingComponent from '../components/TableRestockingComponent';
import { validateSession } from  '../utils/functions';
import "react-datepicker/dist/react-datepicker.css";

var config = process.env;

class RestockingPage extends Component {

	constructor(){
        super();
        this.state = {
            loading: false,
            checked: false,
            dateSelected: new Date(),
            role: 0,
            mode: 0,
            products: {},
            selectedRestockingsAmmount: 0
        }
        this.childRef = React.createRef();
	}

    changeStatus = () => {
        // Invocar la función del componente hijo
        this.childRef.current.changeMasiveStatus();
    }

	async componentDidMount() {
        let role = await validateSession()
        this.setState({ checked: true, role:  role});
    }

    addStock = () => {
        window.location.href = "/addRestocking";
    }

    addPayment = () => {
        this.setState({ mode: 1});
    }

    // Función para actualizar el monsto seleccionado desd el componente hijo
    setSelectedRestockingsAmmount = (newValue) => {
        this.setState({ selectedRestockingsAmmount: newValue });
    }


    renderPurchasesStatistics = () => {        
        return (
        <div className="row align-items-center justify-content-center">    
            <div className="col-md-3 card card-small mb-4 ml-3" style={{ borderRadius: '0.625rem', padding: '15px'}}>
                <div className="row">
                    <div className="col">  
                        <span class="text-muted d-block mb-2 text-center">Monto total de reposiciones</span>
                        <div className="col-md-12 d-flex align-items-center justify-content-center" style={{gap: '3px', fontSize: '20px'}}>
                            <span class="text-muted d-block mb-2">$ {this.state.selectedRestockingsAmmount.toLocaleString('de-DE')}</span>
                        </div>                                   
                    </div>
                </div>
            </div>     
            
            <div className="row align-items-center justify-content-center">
                <button onClick={() => this.changeStatus()} type="button" className="mb-2 btn btn-primary mr-2" style={{marginLeft: '50px', fontSize: '25px'}} >Ingresar Pago</button>
            </div>           
        </div>)
    }


	render() {
        if (!this.state.checked) {
            return (<div></div>);
        } else {

            let subtitle = (this.state.mode === 0) ? "Reposición de stock" : "Hacer pago masivo"

            return (  
                <div className="container-fluid">
                    <div className="row">
                        <SidebarComponent itemSelected={13} role={this.state.role}/>
                        <main className="main-content col-lg-10 col-md-9 col-sm-12 p-0 offset-lg-2 offset-md-3">
                            <NavbarComponent />
                            <div className="main-content-container container-fluid px-4 mb-4" style={{paddingBottom: '80px'}}>
                                <HeaderComponent title="Stocks" subtitle={subtitle}/>
                                {this.state.mode === 1 ? this.renderPurchasesStatistics() : ""}
                                <TableRestockingComponent ref={this.childRef} role={this.state.role} mode={this.state.mode} setSelectedRestockingsAmmount={this.setSelectedRestockingsAmmount}/>
                            </div>
                        </main>
                    </div>c
                    {this.state.mode === 0 ? 
                    (<div onClick={() => this.addStock()} class="color-switcher-toggle animated pulse infinite">
                        <i class="material-icons">add</i>
                    </div>) : ""}
                    {this.state.mode === 0 && this.state.role === 1 ? 
                    <div onClick={() => this.addPayment()} class="color-switcher-toggle animated pulse infinite" style={{right: '80px'}}>
                        <i class="material-icons">attach_money</i>
                    </div> : ""}
                </div>
            );
        }
    }
}

export default RestockingPage;