import React, { Component } from 'react';
var config = process.env;

class PurchaseShippingComponent extends Component {

    constructor(){
        super();
        this.state = {
            method: 2, //1=A_domicilio | 2=Retiro_en_local
            providers: []
        }
    }

    componentDidMount() {
        //Traigo los providers
        var request = {
            method: 'GET',
            headers: { 
                'access-token': localStorage.getItem('token'),
                'Content-Type': 'application/json',
            }
        };
        fetch(config.REACT_APP_BASE_URL + config.REACT_APP_VERSION + 'provider/shipping', request)
        .then(results => { return results.json(); })
        .then(data => {
            if (data.error > 0) {
                alert(data.message);
            } else {
                this.setState({
                    providers: data.data
                });
            }
        });
        const inputCedula = document.getElementById('cedula');
        inputCedula.addEventListener('focusout', (event) => {
            if (inputCedula.value != undefined && inputCedula.value != '') {
                inputCedula.classList.remove("is-invalid");
            } else {
                inputCedula.classList.add("is-invalid");
                return
            }
            var request = {
                method: 'GET',
                headers: { 
                    'access-token': localStorage.getItem('token'),
                    'Content-Type': 'application/json',
                }
            };
            fetch(config.REACT_APP_BASE_URL + config.REACT_APP_VERSION + 'user/' + inputCedula.value, request)
            .then(results => { return results.json(); })
            .then(data => {
                if (data.data != undefined) {
                    document.getElementById("email").value = data.data.email;
                    document.getElementById("phone").value = data.data.phone;
                    document.getElementById("firstname").value = data.data.firstName;
                    document.getElementById("lastname").value = data.data.lastName;
                    document.getElementById("department").value = data.data.city;
                    document.getElementById("address").value = data.data.address;
                }
            });
        });
        const inputFirstname = document.getElementById('firstname');
        inputFirstname.addEventListener('focusout', (event) => {
            if (inputFirstname.value != undefined && inputFirstname.value != '') {
                inputFirstname.classList.remove("is-invalid");
            } else {
                inputFirstname.classList.add("is-invalid");
            }
        });
        const inputLastName = document.getElementById('lastname');
        inputLastName.addEventListener('focusout', (event) => {
            if (inputLastName.value != undefined && inputLastName.value != '') {
                inputLastName.classList.remove("is-invalid");
            } else {
                inputLastName.classList.add("is-invalid");
            }
        });
        const inputCity = document.getElementById('department');
        inputCity.addEventListener('focusout', (event) => {
            if (inputCity.value != undefined && inputCity.value != '') {
                inputCity.classList.remove("is-invalid");
            } else {
                inputCity.classList.add("is-invalid");
            }
        });
        const inputAddress = document.getElementById('address');
        inputAddress.addEventListener('focusout', (event) => {
            if (inputAddress.value != undefined && inputAddress.value != '') {
                inputAddress.classList.remove("is-invalid");
            } else {
                inputAddress.classList.add("is-invalid");
            }
        });
    }

    changeShippingMethod = (method) => {
        this.setState({method: method})
        this.props.onChangeData(method);
    }

    getProviders = () => {
        var list = [];
        var defaultProvider = undefined;
        this.state.providers.forEach((item, i) => {
            if (item.isDefaultProvider == 1) {
                defaultProvider = item.providerId;
            }
            list.push(<option value={item.providerId}>{item.name}</option>);
        }); 
        return (
            <select id="provider" className="custom-select" defaultValue={defaultProvider}>{list}</select>
        )
    }

    render() {
        return (
            <div className="col-lg-12">
                <div className="card card-small mb-4">
                    <div className="card-header border-bottom">
                        <span>Envio</span>
                    </div>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item p-3">
                            <div className="row">
                                <div className="col-6">
                                    <div class="form-group">
                                        <label>Tipo de envio</label>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <button type="button" style={{float: 'right'}} className={`mb-2 btn mr-2 ${this.state.method == 2?'btn-secondary':'btn-outline-secondary'}`} onClick={() => this.changeShippingMethod(2)}>Retiro en local</button>
                                    <button type="button" style={{float: 'right'}} className={`mb-2 btn mr-2 ${this.state.method == 1?'btn-secondary':'btn-outline-secondary'}`} onClick={() => this.changeShippingMethod(1)}>A domicilio</button>
                                </div>
                            </div>

                            <div className="form-row ">
                                <div className="form-group col-md-3">
                                    <label>Cédula</label>
                                    <input type="text" className="form-control" id="cedula" placeholder="Cédula"/>
                                </div>
                                <div className="form-group col-md-5">
                                    <label>Correo</label>
                                    <input type="text" className="form-control" id="email" placeholder="Correo electrónico"/>
                                </div>
                                <div className="form-group col-md-4">
                                    <label>Teléfono</label>
                                    <input type="text" className="form-control" id="phone" placeholder="Teléfono"/>
                                </div>
                                <div className="form-group col-md-6">
                                    <label>Nombre</label>
                                    <input type="text" className="form-control" id="firstname" placeholder="Nombre de quien retira"/>
                                </div>
                                <div className="form-group col-md-6">
                                    <label>Apellido</label>
                                    <input type="text" className="form-control" id="lastname" placeholder="Apellido de quien retira"/>
                                </div>
                            </div>

                            <div className="container_1" style={{display: this.state.method == 1?'block':'none'}}>
                                {/* Container de a domicilio */}
                                <div className="form-row ">
                                    <div className="form-group col-md-4">
                                        <label>Departamento</label>
                                        <select id="department" className="custom-select">
                                            <option value="Montevideo">Montevideo</option>
                                            <option value="Artigas">Artigas</option>
                                            <option value="Canelones">Canelones</option>
                                            <option value="Cerro Largo">Cerro Largo</option>
                                            <option value="Colonia">Colonia</option>
                                            <option value="Durazno">Durazno</option>
                                            <option value="Flores">Flores</option>
                                            <option value="Florida">Florida</option>
                                            <option value="Lavalleja">Lavalleja</option>
                                            <option value="Maldonado">Maldonado</option>
                                            <option value="Paysandú">Paysandú</option>
                                            <option value="Rio Negro">Rio Negro</option>
                                            <option value="Rivera">Rivera</option>
                                            <option value="Rocha">Rocha</option>
                                            <option value="Salto">Salto</option>
                                            <option value="San José">San José</option>
                                            <option value="Soriano">Soriano</option>
                                            <option value="Tacuarembó">Tacuarembó</option>
                                            <option value="Treinta y Tres">Treinta y Tres</option>
                                        </select>
                                    </div>
                                    <div className="form-group col-md-4">
                                        <label>Ciudad</label>
                                        <input type="text" className="form-control" id="city" placeholder="Ingrese la ciudad"/>
                                    </div>
                                    <div className="form-group col-md-4">
                                        <label>Dirección</label>
                                        <input type="text" className="form-control" id="address" placeholder="Ingrese la dirección"/>
                                    </div>
                                    <div className="form-group col-md-3">
                                        <label>Hora de preferencia</label>
                                        <select id="hour_preference" className="custom-select">
                                            <option value="09-12">09-12</option>
                                            <option value="12-15">12-15</option>
                                            <option value="15-18">15-18</option>
                                        </select>
                                    </div>
                                    <div className="form-group col-md-3">
                                        <label>Proveedor</label>
                                        { this.getProviders() }
                                    </div>
                                </div>
                            </div>

                            <div className="container_2" style={{display: this.state.method == 2?'block':'none'}}>
                                {/* Container de retiro en local */}
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        );
    }
}

export default PurchaseShippingComponent;